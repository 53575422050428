import * as moment from 'moment-timezone';
import "moment/locale/da";
import { Localization } from "../localization-module";

export class Countdown {

    public init() {

        const timerWrapper = $(".timer-wrapper");

        if (timerWrapper.length === 0) {
            return;
        }

        let $timeLeft = timerWrapper.find(".time-left") as JQuery<HTMLElement>;
        const $description = timerWrapper.find(".description") as JQuery<HTMLElement>;
        const $showCountdown = timerWrapper.find("#show-countdown") as JQuery<HTMLElement>;
        const $countdownTo = timerWrapper.find("#countdown-to") as JQuery<HTMLElement>;
        const $currentDate = timerWrapper.find("#current-date") as JQuery<HTMLElement>;
        const currentDateString = $currentDate.val() as string;
        const currentDate = moment(currentDateString, 'DD-MM-YYYY HH:mm:ss').toDate();

        if($showCountdown.val() === "True") {

            $description.html(Localization.getText("IndtilValgAfForloeb"));

            // Set the date we're counting down to
            const date = $countdownTo.val() as string;
            const countDownDate = moment(date, 'DD-MM-YYYY HH:mm:ss').toDate().getTime();
            const time = currentDate.getTime();

            if(countDownDate < time) {
                setDuKanVaelgeForloeb($timeLeft, $description);

                return;
            }

            // Update the count down every 1 second
            const interval = setInterval(function() {
                const utcDateVal = new Date().getUTCDate();
                const utcMonth = new Date().getUTCMonth();
                const utcYear = new Date().getUTCFullYear();
                const utcHour = new Date().getUTCHours();
                const utcMinutes = new Date().getUTCMinutes();
                const utcSeconds = new Date().getUTCSeconds();
                const dkTimezoneOffset = moment().tz("Europe/Copenhagen").utcOffset();
                const utcDate = new Date(utcYear, utcMonth, utcDateVal, utcHour, utcMinutes, utcSeconds, null);
                const utcDateWithOffset = moment(utcDate).add(dkTimezoneOffset, 'm').toDate();

                const now = utcDateWithOffset.getTime();
                const distance = countDownDate - now;
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                const localizedDays = Localization.getText("Dage") as string;
                const localizedDay = Localization.getText("Dag") as string;

                let daysText = (days > 1 ? days + ' ' + localizedDays.toLowerCase() + ' ' : (days === 1 ? days + ' ' + localizedDay.toLowerCase() + ' ' : ''));
                let hoursText = (days > 0 ? prependZero(hours) + ':' : (hours > 0 ? prependZero(hours) + ':' : ''));
                let minutesText = prependZero(minutes) + ':';

                let secondsText = prependZero(seconds);

                let timeLeft = daysText + hoursText + minutesText + secondsText;
                const $timerWrapper = $(".timer-wrapper");

                $timeLeft.html(timeLeft);

                if (distance < 0) {
                    clearInterval(interval);
                    setDuKanVaelgeForloeb($timeLeft, $description);
                    $(".vaelg-forloeb").removeAttr("disabled");
                    $(".vaelg-forloeb").prop("title", "");
                    $("#din-tur-modal").modal();
                }
            }, 1000);
        }
        else {
            $timeLeft.html("");
            $description.html("");
        }

    }
}

const prependZero = (timeNumber : number) => {
    return ('0' + timeNumber).slice(-2)
}

const setDuKanVaelgeForloeb = ($timeLeft : JQuery<HTMLElement>, $description: JQuery<HTMLElement> ) => {
    $timeLeft.html(Localization.getText("DuKanNuVaelgeForloeb"));
    $description.html("");
}
