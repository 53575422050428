import "jQueryExtensions";
import {Countdown} from "./modules/countdown-module";

declare global {
    interface Window {
        successMessage: string;
        infoMessage: string;
        errorMessage: string;
    }
}

(function() {
    $(document).ready(function() {
        new Countdown().init();
    });
})();
